export default {
  siteTitle: 'Get bonus',
  blogerName: 'SLOTSDASMENINAS',
  socialsList: [
    {
      name: 'instagram',
      url: 'https://www.instagram.com/munarimichele/?igshid=MzRlODBiNWFlZA%3D%3D'
    },
    {
      name: 'telegram',
      url: 'https://t.me/+HBzA-PtebMQ4N2Fh'
    },
    {
      name: 'telegram',
      url: 'https://t.me/+xQPy3GYw7Lw3NzIx'
    },
    {
      name: 'whatsapp',
      url: 'https://wa.me/message/N5ZESB4VRLL5N1'
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-ircp01.com/c76916c1f',
      gameTitle: 'Book of Kemet',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: '1go',
      url: 'https://1gocasino.life/cee5c6185',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://monrocasino.life/c5b4c6867',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c6066fa61',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c56241d7d',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c7621deee',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c1399e5c4',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c6299e46d',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/cd029738c',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c7569338f',
      gameTitle: 'Jet Air (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>SLOTSDASMENINAS</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'SLOTSDASMENINAS',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado',
  theme: 'dark'
};
